import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Container,

  Navigation,
  NavigationItem,

  BeltContainer,
  FooterBelt,
  FooterBeltShape,
  FooterBeltText,

  Copyright
} from './Footer.styled';

import { Wrapper } from '../';
import colors from '../../theme/colors';

import { getCorrectTermsFileUrl, getCoorectCookieFileUrl, getCorrectPolicyFileUrl } from '../../data/terms';


export default () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <Container>
      <Wrapper>
        <Navigation>
          <NavigationItem><a target='_blank' href={`/${getCorrectTermsFileUrl(lang)}`}>{t('termsAndConditions')}</a></NavigationItem>
          <NavigationItem><a target='_blank' href={`/${getCorrectPolicyFileUrl(lang)}`}>{t('privacyPolicy')}</a></NavigationItem>
          <NavigationItem><a target='_blank' href={`/${getCoorectCookieFileUrl(lang)}`}>{t('cookiePolicy')}</a></NavigationItem>
        </Navigation>
      </Wrapper>
      <div style={{ backgroundColor: colors.primary }}>
        <Wrapper>
          <BeltContainer>
            <FooterBelt>
              <FooterBeltShape />
              <FooterBeltText>{t('roofWindowsFor')} <strong>{t('smartProfessionals')}</strong></FooterBeltText>
            </FooterBelt>
          </BeltContainer>
        </Wrapper>
      </div>
      <Wrapper>
        <Copyright>&copy; 2020 dakea.net</Copyright>
      </Wrapper>
    </Container>
  );
};